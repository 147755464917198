import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Card } from 'antd';
import { auth, signInWithEmailAndPassword, db } from '../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from "firebase/auth";

const Login = () => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null); 
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [message, setMessage] = useState("");

  const handleRefresh = () => {
    navigate('/profile');
    window.location.reload();
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        
        const userDoc = await getDoc(doc(db, 'members', currentUser.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          message.error("User data not found!");
        }
      } else {
        setUser(null);
        setUserData(null);
      }
    });
    return () => unsubscribe(); 
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { email, password } = values;
      await signInWithEmailAndPassword(auth, email, password);
      message.success('Login successful!');
      onClose();
      setUser(auth.currentUser); // Update the user state with the logged-in user
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      message.success('Logged out successfully');
      setUser(null);
      setUserData(null); // Clear user data on logout
    } catch (error) {
      message.error('Failed to log out');
    }
  };

  const handleResetPassword = async (values) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, values.email);
      setMessage("Password reset email sent. Check your inbox.");
    } catch (error) {
      setMessage("Error: " + error.message);
    }
    setLoading(false);
  };

  return (
    <div className='text-center content-center self-center flex mt-28 justify-center'>

        {user ? (
          // Display user details if logged in
          <div className='text-center'>
            <Card className='w-full'>
              <img
                src={userData?.profile_image}
                alt="Profile_image"
                className="rounded-full inline w-32 border-2 h-32 object-cover"
              />
              <p className='flex text-start flex-col'><strong className='text-gray-400 p-2'>Full Name</strong> 
                <p className='w-96 p-2 border-2 rounded-xl'>
                  {`${userData?.first_name} ${userData?.middle_name} ${userData?.last_name}`}
                </p>
              </p>

              <p className='flex text-start flex-col'><strong className='text-gray-400 p-2'>Email Address</strong> 
                <p className='w-96 p-2 border-2 rounded-xl'>{user.email}</p>
              </p>

              <p className='flex text-start flex-col'><strong className='text-gray-400 p-2'>Phone Number</strong> 
                <p className='w-96 p-2 border-2 rounded-xl'>{userData?.phone_number}</p>
              </p>

              <p className='flex text-start flex-col'><strong className='text-gray-400 p-2'>Pew</strong> 
                <p className='w-96 p-2 border-2 rounded-xl'>{userData?.pew}</p>
              </p>
              
              <div className='flex gap-5'>
                <p className='flex text-start flex-col'><strong className='text-gray-400 p-2'>State of Origin</strong> 
                  <p className='w-44 p-2 border-2 rounded-xl text-center'>{userData?.state_of_origin}</p>
                </p>

                <p className='flex text-start flex-col '><strong className='text-gray-400 p-2'>Bethel</strong> 
                  <p className='w-44 p-2 border-2 rounded-xl text-center '>{userData?.bethel}</p>
                </p>
              </div>

              <Link onClick={handleRefresh}  to={"/profile"}>
                <Button className='bg-[#ff0000] rounded-xl font-bold w-56 h-10 self-center mt-5 text-white'>
                  Edit Profile
                </Button>
              </Link>

              <Button className='bg-gray-400 rounded-xl font-bold w-56 h-10 self-center mt-5 text-white' onClick={handleLogout}>
                Logout
              </Button>
            </Card>
          </div>
        ) : (
          <div>
              {!isResetPassword ? (
                // Login Form
                <Form form={form} layout="vertical" onFinish={onFinish}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: "Enter your email" }]}
                  >
                    <Input className="h-12 w-96" placeholder="Enter your email" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: "Enter your password" }]}
                  >
                    <Input.Password className="h-12 w-96" placeholder="Enter your password" />
                  </Form.Item>
                <div className='flex flex-col gap-5'>
                  <Button className=" h-12 self-center text-blue-500 cursor-pointer"
                    onClick={() => setIsResetPassword(true)}>
                    Forgot Password? Reset
                  </Button>
                  <Button
                    className="bg-[#ff0000] rounded-2xl font-bold w-56 h-10 self-center"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    >
                    Login
                  </Button>
                </div>
                </Form>
              ) : (
                // Reset Password Form
                <Form className='shadow-md p-10 rounded-md' form={form} layout="vertical" onFinish={handleResetPassword}>
                  <h2 className="text-xl font-bold mb-3">Reset Your Password</h2>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: "Enter your email" }]}
                  >
                    <Input className="h-12 w-96 shadow-sm" placeholder="Enter your email" />
                  </Form.Item>
                  <Button
                    className="bg-blue-500 shadow-md rounded-2xl text-center h-12 font-bold w-56 self-center"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Send Reset Email
                  </Button>
                  <Link
                    href="#"
                    className="block text-red-500 mt-3 cursor-pointer"
                    onClick={() => setIsResetPassword(false)}
                  >
                    Return to Login
                  </Link>
                  {message && <p className="text-sm text-gray-600 mt-2">{message}</p>}
                </Form>
              )}
          </div>
          
        )}
    </div>
  );
};

export default Login;
