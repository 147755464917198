import React, { useState, useEffect, useRef } from "react";
import {
  PlusOutlined,
  UploadOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  message,
  Switch,
  Radio,
  Upload,
  AutoComplete,
  Collapse,
  Modal,
} from "antd";
import {
  auth,
  db,
  createUserWithEmailAndPassword,
  setDoc,
  doc,
  collection,
  getDocs,
  storage,
  getDownloadURL,
  ref,
  uploadBytes,
  getDoc,
} from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { toast } from "react-toastify";
import countryList from "country-list";
import countries from "world-countries";
const { Option } = Select;

const countryOptions = countryList.getNames().map((name) => {
  const code = countryList.getCode(name);
  return {
    value: name,
    label: name,
    flag: `https://flagcdn.com/w40/${code.toLowerCase()}.png`, // Flag URL
  };
});

// Custom render for selected value
const customSingleValue = ({ data }) => (
  <div className="flex items-center gap-2">
    <img src={data.flag} alt={data.label} width="20" height="15" />
    <span>{data.label}</span>
  </div>
);

// Custom render for dropdown options
const customOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div ref={innerRef} {...innerProps} className="flex items-center gap-2 p-2 hover:bg-gray-100 cursor-pointer">
      <img src={data.flag} alt={data.label} width="20" height="15" />
      <span>{data.label}</span>
    </div>
  );
};

const Register = ({value }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isStakeholder, setIsStakeholder] = useState(false);
  const [isPriest, setIsPriest] = useState(false);
  const [isNot, setIsNot] = useState(false);
  const [isPosted, setIsPosted] = useState(null);
  const [qualifications, setQualifications] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categorie, setCategorie] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();
  const webcamRef = useRef(null);

   const [isMember, setIsMember] = useState(true);

  const handleChangeM = (value) => {
    setIsMember(value !== "Non Member");
  };
  

  //fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "BCS"));
        const uniqueCategories = new Set();
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.category) uniqueCategories.add(data.category);
        });
        setCategories(Array.from(uniqueCategories));
      } catch (error) {
        message.error("Failed to fetch categories");
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchCategorie = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "BCS"));
        const uniqueCategories = new Set();
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.department_name) uniqueCategories.add(data.department_name);
        });
        setCategorie(Array.from(uniqueCategories));
      } catch (error) {
        message.error("Failed to fetch department_name");
      }
    };
    fetchCategorie();
  }, []);

  //capture the photo or image of the user
  const capturePhoto = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCameraOpen(false);

    const blob = await fetch(imageSrc).then((res) => res.blob());
    setProfileImage(blob);

    const storageRef = ref(storage, `profile-images/${Date.now()}.jpg`);
    const snapshot = await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(snapshot.ref);

    setImagePreview(url);
    form.setFieldsValue({ profile_image: url });
  };

  //submit user entreis to db
  const onFinish = async (values) => {
    if(!imagePreview){
      message.error("Please click the camera icon and take a passport photograph");
    }else{
      setLoading(true);
      try {
        const {
          email,
          password,
          first_name,
          middle_name,
          last_name,
          office,
          pew,
          gender,
          country,
          village,
          country_of_residence,
          country_of_origin,
          next_of_kin1,
          bethel,
          is_stakeholder,
          is_priest,
          pew_position,
          posted,
          station_posted_to,
          previous_stations,
          officer_at,
          year_assumed,
          position,
          qualifications,
          qualificationsList,
          qualification_documents,
          next_of_kin,
          means_of_identification,
          lga_of_origin,
          nation_of_worship,
          residential_address,
          phone_number,
          home_town,
          residing_state,
          state_of_origin,
          profile_image,
        } = values;
    
        // Check if the email already exists
        const userSnapshot = await getDoc(doc(db, "members", email));
        if (userSnapshot.exists()) {
          toast.error("A user with this email already exists!");
          setLoading(false);
          return;
        }
    
        // Create user with email and password
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
    
        let documentImageUrl = profile_image || null;
        let profileImageUrl = profile_image;
    
        if (uploadedFile) {
          const storageRef = ref(
            storage,
            `users/${user.uid}/document_image/${uploadedFile.name}`
          );
          await uploadBytes(storageRef, uploadedFile);
          documentImageUrl = await getDownloadURL(storageRef);
        }
    
        if (profileImageUrl) {
          const profileImageRef = ref(
            storage,
            `users/${user.uid}/profile_image/${new Date().toISOString()}`
          );
          await uploadBytes(profileImageRef, profileImageUrl);
          profileImageUrl = await getDownloadURL(profileImageRef);
        }
    
        // Handle qualification documents upload if "Qualifications" is yes
        let uploadedQualificationDocuments = [];
        if (qualifications === "yes" && qualification_documents && qualification_documents.length > 0) {
          for (const doc of qualification_documents) {
            const qualificationRef = ref(
              storage,
              `users/${user.uid}/qualifications/${doc.name}`
            );
            await uploadBytes(qualificationRef, doc);
            const docUrl = await getDownloadURL(qualificationRef);
            uploadedQualificationDocuments.push(docUrl);
          }
        }
    
        // Build user data
        const userData = {
          uid: user.uid,
          email,
          bethel,
          first_name,
          middle_name,
          last_name,
          pew: pew,
          pew_position,
          gender,
          country,
          country_of_residence,
          country_of_origin,
          village,
          office: office || null,
          is_stakeholder,
          is_priest: isPriest,
          qualifications: qualifications === "yes" ? qualifications : null,
          qualificationsList: qualificationsList === "yes" ? qualificationsList : null,
          qualification_documents:
            qualifications === "yes" ? uploadedQualificationDocuments : null,
          next_of_kin: is_priest ? next_of_kin : null,
          posted: is_priest ? posted : null,
          station_posted_to:
            is_priest && posted === "yes" ? station_posted_to : null,
          previous_stations:
            is_priest && posted === "yes" ? previous_stations : null,
          residential_address,
          lga_of_origin,
          nation_of_worship,
          phone_number,
          home_town,
          residing_state,
          next_of_kin1,
          state_of_origin,
          officer_at:  officer_at || null,
          year_assumed: year_assumed || null,
          position: position || null,
          means_of_identification,
          document_image: documentImageUrl,
          profile_image: imagePreview,
        };
    
        // Filter out null or undefined values
        const filteredUserData = Object.fromEntries(
          Object.entries(userData).filter(
            ([_, value]) => value !== undefined && value !== null
          )
        );
    
        // Store user data in Firestore
        await setDoc(doc(db, "members", user.uid), filteredUserData);
    
        toast.success("Registration successful!");
        navigate(`/profile/${user.uid}`, { replace: true });
        window.location.reload();
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          toast.error("This email is already in use.");
        } else {
          toast.error("An error occurred: " + error.message);
        }
        setLoading(false);
      }
    }
  };
  
  //upload all images to the storge
  const uploadProps = {
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      const isSmallEnough = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        toast.error("You can only upload image files!");
      }
      if (!isSmallEnough) {
        toast.error("Image must be smaller than 2MB!");
      }
      setUploadedFile(isImage && isSmallEnough ? file : null);
      return false;
    },
  };

  return (
    <div className="p-5 max-w-4xl mx-auto">
      <div className="flex items-center justify-center mb-5 lg:mt-10 mt-5">
        <div className="relative">
          <img
            src={
              imagePreview ||
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5seV9y_a20glyuiIKDENNpXYgWDuNFnQ5fIopKOrpsQ_ms8Kt33kZJSiS7m6JwbVIqfM&usqp=CAU"
            }
            alt="Profile Preview"
            className="w-40 h-40 rounded-full object-cover border-2 border-blue-500"
          />
          <Button
            type="primary"
            shape="circle"
            icon={<CameraOutlined color="#000" />}
            className="absolute bottom-5 right-0 bg-blue-500"
            onClick={() => setCameraOpen(true)}
            />
        </div>
      </div>
            <p className="text-center -mt-2 text-xs text-gray-500 mb-5">Click the camera icon to take a passport</p>

        <Link to={"/login"} className="my-2 text-center block">
           <Button className="bg-blue-500 my-3 h-12" type="primary">Already have an account ? Login</Button>
        </Link>

      <Modal
        visible={cameraOpen}
        title="Take a Photo"
        onCancel={() => setCameraOpen(false)}
        footer={null}
      >
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          className="w-full rounded-md"
          mirrored
        />
        <Button type="primary" className="w-full mt-2 text-blue-600" onClick={capturePhoto}>
          Capture Photo
        </Button>
      </Modal>

      <Form
        form={form}
        className="text-center"
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Enter your Email" }]}
            >
              <Input className="h-12" placeholder="Please Enter your Email" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[{ required: true, message: "Your First Name" }]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter your First Name"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
        <Col span={12}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[{ required: true, message: "Your Last Name" }]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter your Last Name"
              />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item
              name="middle_name"
              label="Middle Name"
              rules={[{ required: true, message: "Enter your Middle Name" }]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter your Middle Name"
              />
            </Form.Item>
          </Col> */}
            <Col span={12}>
                <Form.Item
                  name="next_of_kin1"
                  label="Next of kin"
                  rules={[
                    { required: true, message: "Enter your Next of kin" },
                  ]}
                >
                  <Input
                    className="h-12"
                    placeholder="Please Enter your Next of kin"
                  />
                </Form.Item>
              </Col>
         
        </Row>

        <Row gutter={16}>

        <Col span={12}>
            <Form.Item
              name="phone_number"
              label="Your Phone Number"
              rules={[
                { required: true, message: "Enter your valid Phone Number" },
              ]}
            >
              <Input type="Number" className="h-12" placeholder="Enter your Valid Phone Number" />
            </Form.Item>
          </Col>

          {/* <Col span={12}>
            <Form.Item
              name="next_of_kin1"
              label="Next of kin"
              rules={[
                { required: true, message: "Enter your Next of kin" },
              ]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter your Next of kin"
              />
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="pew"
              label="Pew"
              rules={[{ required: true, message: "Please select Pew" }]}
            >
           <Select className="h-12" placeholder="Please choose" onChange={handleChangeM}>
                  <Option value="Non Member">Not Applicable </Option>
                  <Option value="Sabbath">Sabbath </Option>
                  <Option value="Congregation">Congregation</Option>
                  <Option value="Chorister">Chorister</Option>
                  <Option value="Spirited Child">Spirited Child</Option>
                  <Option value="Elder">Elder</Option>
                  <Option value="Ordained One">Ordained One</Option>
                  <Option value="Christ Natural Preacher">Christ Natural Preacher</Option>
                  <Option value="Christ Practical Student">Christ Practical Student</Option>
                  <Option value="Senior Christ Servant">Senior Christ Servant</Option>
                  <Option value="True Christ Witness">True Christ Witness</Option>
                  <Option value="Blessed Mother">Blessed Mother </Option>
                  <Option value="Divine Vanguard">Divine Vanguard </Option>
                  <Option value="Bishop">Bishop </Option>
                  <Option value="Christ Ambassador">Christ Ambassador </Option>
                  <Option value="Eminence">Eminence </Option>
                  <Option value="Archbishop">Archbishop </Option>
                  <Option value="Christ Shepherd">Christ Shepherd </Option>
                  <Option value="144,000 Virgin">144,000 Virgin </Option>
                </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="gender"
              label="Gender"
              rules={[{ required: true, message: "Please select gender" }]}
            >
              <Select className="h-12" placeholder="Please choose gender">
                <Option value="brother">Brother</Option>
                <Option value="sister">Sister</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Residential Address Section */}
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="residing_state"
          label="State of Residence"
          rules={[{ required: true, message: "Enter your State of Residence" }]}
        >
          <Input
            className="h-12"
            placeholder="Enter your State of Residence"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="residential_address"
          label="Residential Address"
          rules={[{ required: true, message: "Enter your Residential Address" }]}
        >
          <Input
            className="h-12"
            placeholder="Enter your Residential Address"
          />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>

             <Col span={12}>
              <Form.Item name="country_of_residence" label="Country Of Residence" rules={[{required: true, message: 'Enter country', },]} >
              <Select className="h-12"
              options={countryOptions}
              placeholder="Search and select a country..."
              // onChange={onChange}
              value={countryOptions.find((option) => option.value === value)}
              components={{ SingleValue: customSingleValue, Option: customOption }}
              isSearchable
            />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="country_of_origin" label="Country Of Origin" rules={[{required: true, message: 'Enter country', },]} >
                <Select className="h-12"
                 options={countryOptions}
                placeholder="Please choose"
                // onChange={onChange}
                value={countryOptions.find((option) => option.value === value)}
              />
              </Form.Item>
            </Col>
    </Row>

    {/* Origin Details Section */}
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="state_of_origin"
          label="State of Origin"
          rules={[{ required: true, message: "Enter your State of Origin" }]}
        >
          <Input
            className="h-12"
            placeholder="Enter your State of Origin"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="lga_of_origin"
          label="LGA of Origin / County / Province"
          rules={[{ required: true, message: "Enter your LGA of Origin / County / Province" }]}
        >
          <Input
            className="h-12"
            placeholder="Enter your LGA of Origin or County"
          />
        </Form.Item>
      </Col>
    </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="village"
              label="Village"
              rules={[
                { required: true, message: "Village" },
              ]}
            >
              <Input
                className="h-12"
                placeholder="Village"
              />
            </Form.Item>
          </Col>
{
  isMember === false ? null :
          <Col span={12}>
            <Form.Item
              name="nation_of_worship"
              label="CURRENT BCS NATION OF WORSHIP"
              rules={[{ required: true, message: "Enter Nation" }]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter Your Nation of worship"
              />
            </Form.Item>
          </Col>
}
        </Row>

    
{
  isMember === false ? null :
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="bethel"
              label="Bethel"
              rules={[{ required: true, message: "Enter Bethel" }]}
            >
              <Input className="h-12" placeholder="Please Enter Bethel" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="bethel_Location"
              label="Bethel Address"
              rules={[
                {
                  required: true,
                  message: "Please Enter Bethel Address",
                },
              ]}
            >
              <Input
                className="h-12"
                placeholder="Enter Bethel Address"
              />
            </Form.Item>
          </Col>
        </Row>
}

        <Row gutter={16}>
        {
  isMember === false ? null :
          <Col span={12}>
            <Form.Item
              name="bethel_country"
              label="Country where the bethel is located"
              rules={[
                { required: true, message: "Enter country where Bethel is located " },
              ]}
            >
                    <Select className="h-12"
                        options={countryOptions}
                        placeholder="Please choose"
                        // onChange={onChange}
                        value={countryOptions.find((option) => option.value === value)}
                      />
            </Form.Item>
          </Col>
}
          <Col span={12}>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Enter Password" }]}
            >
              <Input.Password
                className="h-12"
                placeholder="Please Enter Password"
              />
            </Form.Item>
          </Col>
        </Row>

        {
  isMember === false ? null :
  <>
      <Row gutter={16}>
      <Col span={24}>
        <Form.Item
        name="is_priest"
        label="Are you a Priest? (Click the button below)"
          valuePropName="checked"
        >
          <div
            className={`relative inline-flex items-center h-6 w-12 rounded-full cursor-pointer transition-all duration-300 ${
              isPriest ? "bg-blue-500" : "bg-gray-300"
            }`}
            onClick={() => setIsPriest(!isPriest)}
          >
            <span
              className={`inline-block h-5 w-5 bg-white rounded-full transform transition-transform duration-300 ${
                isPriest ? "translate-x-6" : "translate-x-1"
              }`}
            ></span>
          </div>
        </Form.Item>
      </Col>
    </Row>

      {isPriest && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="posted"
                label="Are you Posted?"
                rules={[{ required: true, message: "Please select Posted status" }]}
              >
                <Select
                  placeholder="Select Posted Status"
                  onChange={(value) => {
                    form.setFieldsValue({ posted: value });
                    setIsPosted(value);
                  }}
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="pew_position"
                label="Current Position/Office in Priesthood"
                rules={[{ required: true, message: "Please enter your Current Position/Office in Priesthood" }]}
              >
                <Input placeholder="Please enter your Current Position/Office in Priesthood" />
              </Form.Item>
            </Col>
          </Row>

          {isPosted === "yes" && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="station_posted_to"
                  label="Station Posted To"
                  rules={[
                    { required: true, message: "Please enter Station Posted To" },
                  ]}
                >
                  <Input placeholder="Enter Station Posted To" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="previous_stations"
                  label="Previous Stations"
                  rules={[
                    { required: true, message: "Please enter Previous Stations" },
                  ]}
                >
                  <Input placeholder="Enter Previous Stations" />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="qualifications"
                label="Qualifications"
                rules={[{ required: true, message: "Please enter your Qualifications" }]}
              >
                <Select
                  placeholder="Select if you have Qualifications"
                  onChange={(value) => {
                    form.setFieldsValue({ qualifications: value });
                    setQualifications(value);
                  }}
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="next_of_kin"
                label="Next of kin's contact"
                rules={[{ required: true, message: "Please enter your Next of Kin contact" }]}
              >
                <Input placeholder="Enter Next of Kin contact" />
              </Form.Item>
            </Col>
          </Row>

          {qualifications === "yes" && (
            <Row gutter={16}>
              <Col span={12}>
              <Form.Item
                name="qualificationsList"
                label="List Your Qualifications"
                rules={[{ required: true, message: "Please enter your Qualifications" }]}
              >
                <Input placeholder="Example: Waec, BSC, Masters, PHD etc..." />
              </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item
                  name="qualification_documents"
                  label="Qualification Documents"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e && e.fileList}
                  rules={[{ required: true, message: "Please upload your documents" }]}
                >
                  <Upload
                    action="/upload"
                    listType="picture"
                    multiple
                  >
                    <Button icon={<UploadOutlined />}>Upload Documents</Button>
                  </Upload>
                </Form.Item>
              </Col> */}
              
            </Row>
          )}

          {/* <Row gutter={16}>
           
          </Row> */}
        </>
      )}
  </>
}


<Row gutter={16}>
      <Col span={24}>
        <Form.Item
          label="Are you an Office Holder /Priest/Staff in any BCS Establishments/Departments/Enterprises(Click the button below)"
          name="is_stakeholder"
          valuePropName="checked"
        >
          <div
            className={`relative inline-flex items-center h-6 w-12 rounded-full cursor-pointer transition-all duration-300 ${
              isStakeholder ? "bg-blue-500" : "bg-gray-300"
            }`}
            onClick={() => setIsStakeholder(!isStakeholder)}
          >
            <span
              className={`inline-block h-5 w-5 bg-white rounded-full transform transition-transform duration-300 ${
                isStakeholder ? "translate-x-6" : "translate-x-1"
              }`}
            ></span>
          </div>
        </Form.Item>
      </Col>
    </Row>
    {isStakeholder && (
    <Collapse>
      <Collapse.Panel header={<span style={{ fontWeight: 'bold', color: '#1890ff' }}>Click here to continue</span>}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="officer_at"
                  label="Select Your Formation Level"
                  rules={[{ required: true, message: 'Enter Officer At' }]}
                >
                  <Select className='h-12' placeholder="Select Office">
                    {categories.map((category) => (
                      <Option key={category} value={category}>
                        {category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="year_assumed"
                  label="Month & Year Of Appointment"
                  rules={[{ required: true, message: 'Enter Year Assumed' }]}
                >
                  <Input type='date' className="h-12" placeholder="Month & Year Of Appointment" />
                </Form.Item>
              </Col>
            </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="position"
          label="Click to select your Formation below(if not highlighted, input manually)"
          rules={[{ required: true, message: 'Enter Office' }]}
        >
          <AutoComplete
            className="h-12 w-full"
            placeholder="Select or enter your office"
            options={categorie.map((category) => ({ value: category }))}
            filterOption={(inputValue, option) =>
              option?.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="office"
          label="Enter your position in the office"
          rules={[{ required: true, message: "Please enter your position in the office" }]}
              >
                <Input className="h-12 w-full" placeholder="Enter your position in the office" />
              </Form.Item>
      </Col>
    </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="means_of_identification"
                label="Means of Identification"
                rules={[{ required: true, message: 'Select Means of Identification' }]}
              >
                <Radio.Group>
                  <Radio value="nin">NATIONAL ID OR NIN FOR NIGERIANS</Radio>
                  <Radio value="drivers_license">DRIVER'S LICENCE NUMBER</Radio>
                  <Radio value="international_passport_id">INTERNATIONAL PASSPORT NUMBER</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
          <Col span={24}>
              <Form.Item
                name="document_image"
                label="Upload Your Means Of Identification"
                rules={[{ required: true, message: 'Please upload your document' }]}
              >
                <Upload {...uploadProps} maxCount={1}>
                  <Button icon={<UploadOutlined />}>Upload Your Means Of Identification</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
    </Collapse.Panel>
  </Collapse>
          )}


            <Button
            className="bg-blue-500 text-white p-5 mt-5 font-bold rounded-lg"
            type="primary"
             htmlType="submit"
            icon={<PlusOutlined />}
            loading={loading}
          >
            {loading ? 'Registering...' : 'Register'}
          </Button>
      </Form>
    </div>
  );
};

export default Register;
