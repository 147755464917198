import React, { useState } from "react";
import { db, storage } from "../../firebase"; // Import your Firebase storage setup
import { collection, doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";

const ChildRegister = () => {
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    dateOfBirth: "",
    parentsName: "",
    baptismalDate: "",
    registeredInSabbath: false,
    image: "", // Field for the profile image URL
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [imageFile, setImageFile] = useState(null); // File input state

  const auth = getAuth();
  const userId = auth.currentUser?.uid; // Logged-in user's ID

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      let imageUrl = "";
      if (imageFile) {
        // Upload image to Firebase Storage
        const storageRef = ref(storage, `child-images/${userId}/${imageFile.name}`);
        await uploadBytes(storageRef, imageFile);
        imageUrl = await getDownloadURL(storageRef);
      }

      const childData = { ...formData, image: imageUrl };

      if (userId) {
        // Create a reference to the sub-collection under the logged-in user's document
        const childDocRef = doc(collection(db, "users", userId, "children"));
        await setDoc(childDocRef, childData); // Save data to a new document in the sub-collection

        setSuccessMessage("Child information saved successfully!");
      } else {
        setErrorMessage("No logged-in user found.");
      }
    } catch (error) {
      console.error("Error saving child information:", error);
      setErrorMessage("Failed to save child information.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-semibold text-gray-800 mb-6">
        Child Registration
      </h2>

      {successMessage && <p className="text-green-600">{successMessage}</p>}
      {errorMessage && <p className="text-red-600">{errorMessage}</p>}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block font-medium text-gray-700 mb-2">
            Child's Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
            placeholder="Enter child's full name"
            required
          />
        </div>

        <div>
          <label className="block font-medium text-gray-700 mb-2">Gender</label>
          <select
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        <div>
          <label className="block font-medium text-gray-700 mb-2">
            Date of Birth
          </label>
          <input
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
            required
          />
        </div>

        <div>
          <label className="block font-medium text-gray-700 mb-2">
            Parent's Name
          </label>
          <input
            type="text"
            name="parentsName"
            value={formData.parentsName}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
            placeholder="Enter parent's name"
            required
          />
        </div>

        <div>
          <label className="block font-medium text-gray-700 mb-2">
            Baptismal Date
          </label>
          <input
            type="date"
            name="baptismalDate"
            value={formData.baptismalDate}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />
        </div>

        <div>
          <label className="block font-medium text-gray-700 mb-2">
            Registered in Sabbath?
          </label>
          <div className="flex items-center space-x-4">
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="registeredInSabbath"
                value="true"
                checked={formData.registeredInSabbath === true}
                onChange={() => setFormData({ ...formData, registeredInSabbath: true })}
                className="form-radio text-blue-500 focus:ring-blue-500"
              />
              <span>Yes</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="registeredInSabbath"
                value="false"
                checked={formData.registeredInSabbath === false}
                onChange={() => setFormData({ ...formData, registeredInSabbath: false })}
                className="form-radio text-blue-500 focus:ring-blue-500"
              />
              <span>No</span>
            </label>
          </div>
        </div>

        <div>
          <label className="block font-medium text-gray-700 mb-2">
            Profile Image
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />
        </div>

        <button
          type="submit"
          className={`w-full px-4 py-2 text-white rounded-lg ${
            loading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
          } focus:outline-none focus:ring-2 focus:ring-blue-400`}
          disabled={loading}
        >
          {loading ? "Saving..." : "Save Information"}
        </button>
      </form>
    </div>
  );
};

export default ChildRegister;
