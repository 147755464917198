import React, { useEffect, useState } from "react";
import { db } from "../../firebase"; // Import Firebase Firestore setup
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Modal, Input, Button, Spin, message, Switch } from "antd";

const ChildrenList = () => {
  const [children, setChildren] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingChild, setEditingChild] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const auth = getAuth();
  const userId = auth.currentUser?.uid; // Logged-in user's ID

  useEffect(() => {
    const fetchChildren = async () => {
      setLoading(true);
      setErrorMessage("");

      try {
        if (!userId) {
          setErrorMessage("No logged-in user found.");
          setLoading(false);
          return;
        }

        const childrenRef = collection(db, "users", userId, "children");
        const childrenQuery = query(childrenRef);
        const querySnapshot = await getDocs(childrenQuery);

        const childrenData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setChildren(childrenData);
      } catch (error) {
        console.error("Error fetching children data:", error);
        setErrorMessage("Failed to fetch children data.");
      } finally {
        setLoading(false);
      }
    };

    fetchChildren();
  }, [userId]);

  const handleEdit = (child) => {
    setEditingChild(child);
    setFormValues(child);
    setModalVisible(true);
  };

  const handleSave = async () => {
    if (!editingChild) return;

    const childDoc = doc(db, "users", userId, "children", editingChild.id);

    try {
      await updateDoc(childDoc, formValues);
      message.success("Child information updated successfully!");

      setChildren((prev) =>
        prev.map((child) =>
          child.id === editingChild.id ? { ...child, ...formValues } : child
        )
      );
      setModalVisible(false);
    } catch (error) {
      console.error("Error updating child data:", error);
      message.error("Failed to update child information.");
    }
  };

  const handleInputChange = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-semibold text-gray-800 mb-6">
        Children
      </h2>

      {loading ? (
        <div className="flex justify-center">
          <Spin size="large" />
        </div>
      ) : errorMessage ? (
        <p className="text-red-600">{errorMessage}</p>
      ) : children.length === 0 ? (
        <p className="text-gray-500">No children data found.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {children.map((child) => (
            <div
              key={child.id}
              className="border p-4 rounded-lg shadow-md text-center bg-gray-50 flex flex-col space-y-2"
            >
              <div className="items-center text-center  space-x-4">
                {child.image ? (
                  <img
                    src={child.image}
                    alt={child.name}
                    className="w-16 block self-center h-16 rounded-full object-cover"
                  />
                ) : (
                  <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center text-gray-700">
                    No Image
                  </div>
                )}
                <h3 className="text-xl font-bold">{child.name}</h3>
              </div>
              <p className="items-center text-center">
              <strong className="text-gray-400">Gender</strong> <p className=" text-lg"> {child.gender} </p> 
              </p>

              <p className="items-center text-center">
              <strong className="text-gray-400">GDAte of Birthender</strong> <p className=" text-lg"> {child.dateOfBirth} </p> 
              </p>

              <p className="items-center text-center">
              <strong className="text-gray-400">Parents Name</strong> <p className=" text-lg"> {child.parentsName} </p> 
              </p>

              <p className="items-center text-center">
              <strong className="text-gray-400">DAte of Baptism</strong> <p className=" text-lg"> {child.baptismalDate} </p> 
              </p>

              <p className="items-center text-center">
              <strong className="text-gray-400">Registered in Sabbath</strong> <p className=" text-lg">{child.registeredInSabbath ? "Yes" : "No"} </p> 
              </p>
              
              <Button
                type="primary"
                className="mt-4 bg-blue-500"
                onClick={() => handleEdit(child)}
              >
                Edit
              </Button>
            </div>
          ))}
        </div>
      )}

      {/* Edit Modal */}
      <Modal
        title="Edit Child Information"
        visible={modalVisible}
        onOk={handleSave}
        onCancel={() => setModalVisible(false)}
      >
        <div className="space-y-4">
          <Input
            placeholder="Name"
            value={formValues.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
          <Input
            placeholder="Gender"
            value={formValues.gender}
            onChange={(e) => handleInputChange("gender", e.target.value)}
          />
          <Input
            placeholder="Date of Birth"
            value={formValues.dateOfBirth}
            onChange={(e) => handleInputChange("dateOfBirth", e.target.value)}
          />
          <Input
            placeholder="Parent's Name"
            value={formValues.parentsName}
            onChange={(e) => handleInputChange("parentsName", e.target.value)}
          />
          <Input
            placeholder="Baptismal Date"
            value={formValues.baptismalDate}
            onChange={(e) => handleInputChange("baptismalDate", e.target.value)}
          />
          <div className="flex items-center space-x-4">
            <span>Registered in Sabbath:</span>
            <Switch
              checked={formValues.registeredInSabbath}
              onChange={(checked) =>
                handleInputChange("registeredInSabbath", checked)
              }
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChildrenList;
