import React, { useState, useEffect, useRef } from 'react';
  import { Button, Form, Input, message, Upload } from 'antd';
  import { UploadOutlined } from '@ant-design/icons';
  import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
  import { doc, getDoc, updateDoc } from 'firebase/firestore';
  import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
  import { useNavigate } from 'react-router-dom';
  import { auth, db, storage } from '../../firebase'; // Ensure correct path
  import { IoMdAddCircleOutline, IoMdArrowForward } from 'react-icons/io';
  import { Drawer, Radio, Space } from 'antd';
import ChildRegister from './CHildRegister';
import ChildrenList from './ChildrenList';
import { CameraOutlined } from '@ant-design/icons';
import Webcam from 'react-webcam';
import { Modal } from 'antd';

const Profile = () => {
  const [form] = Form.useForm();
  const [user, setUser] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [profile_image, setProfile_image] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [placement, setPlacement] = useState('bottom');
  const [placement2, setPlacement2] = useState('left');
  
  
  const webcamRef = useRef(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(profileData.profile_image || "");
  const capturePhoto = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCameraOpen(false);
  
    const blob = await fetch(imageSrc).then((res) => res.blob());
    setProfile_image(blob);
  
    // Upload to Firebase Storage
    const storageRef = ref(storage, `profile-images/${user.uid}_${Date.now()}.jpg`);
    const snapshot = await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(snapshot.ref);
  
    setImagePreview(url);
    setProfileData((prev) => ({ ...prev, profile_image: url }));
    form.setFieldsValue({ profile_image: url });
  
    message.success('Profile photo updated successfully!');
  };

    const showDrawer2 = () => {
      setOpen2(true);
    };
    const onClose2 = () => {
      setOpen2(false);
    };
    const onChange2 = (e) => {
      setPlacement2(e.target.value);
    };
// 2
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
    const onChange = (e) => {
      setPlacement(e.target.value);
    };

    useEffect(() => {
      const auth = getAuth();
      onAuthStateChanged(auth, async (currentUser) => {
        if (currentUser) {
          setUser(currentUser);
          const userDoc = await getDoc(doc(db, 'members', currentUser.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setProfileData(data);
            form.setFieldsValue(data);
          }
        }
      });
    }, [form]);

    const onFinish = async (values) => {
      setLoading(true);
      try {
        let imageUrl = profileData.profile_image; // Keep old image if no new one is uploaded
    
        if (profile_image) {
          const imageRef = ref(storage, `profile_images/${user.uid}`);
          await uploadBytes(imageRef, profile_image);
          imageUrl = await getDownloadURL(imageRef);
        }
    
        const updatedData = { ...values, profile_image: imageUrl };
        await updateDoc(doc(db, 'members', user.uid), updatedData);
    
        message.success('Profile updated successfully!');
        setProfileData(updatedData);
        setProfile_image(null); // Reset the selected image
      } catch (error) {
        message.error('Error updating profile: ' + error.message);
      } finally {
        setLoading(false);
      }
    };
    
    const uploadProps = {
      beforeUpload: (file) => {
        setProfile_image(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setProfileData((prev) => ({ ...prev, profile_image: e.target.result }));
        };
        reader.readAsDataURL(file);
        return false; // Prevent auto upload
      },
      onRemove: () => {
        setProfile_image(null);
        setProfileData((prev) => ({ ...prev, profile_image: profileData.profile_image }));
      },
    };

    const handleLogout = async () => {
      try {
        await signOut(auth);
        message.success('Logged out successfully!');
        navigate('/login');
        window.location.reload();
      } catch (error) {
        message.error('Error logging out: ' + error.message);
      }
    };

    const fromInputRef = useRef(null);
    const toInputRef = useRef(null);

    useEffect(() => {
      const loadAutocomplete = () => {
        if (window.google) {
          const fromAutocomplete = new window.google.maps.places.Autocomplete(fromInputRef.current);
          const toAutocomplete = new window.google.maps.places.Autocomplete(toInputRef.current);

          fromAutocomplete.addListener('place_changed', () => {
            const place = fromAutocomplete.getPlace();
            if (place && place.formatted_address) {
              setProfileData((prevData) => ({
                ...prevData,
                from: place.formatted_address,
              }));
            }
          });

          toAutocomplete.addListener('place_changed', () => {
            const place = toAutocomplete.getPlace();
            if (place && place.formatted_address) {
              setProfileData((prevData) => ({
                ...prevData,
                to: place.formatted_address,
              }));
            }
          });
        } else {
          console.error('Google Maps JavaScript API not loaded');
        }
      };

      const intervalId = setInterval(() => {
        if (window.google) {
          loadAutocomplete();
          clearInterval(intervalId);
        }
      }, 100);

      return () => {
        clearInterval(intervalId);
      };
    }, []);

    return (
      <div className='w-screen md:w-[100%]'>
        <div className='text-center content-center self-center mx-3 lg:mx-10 mt-20 flex justify-center flex-col'>
          <Form form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
            <h2 className='text-center my-10 text-2xl font-bold text-gray-400'>Edit Your Profile </h2>  <h1 className='text-3xl font-bold text-gray-800'>
          Welcome, {profileData.first_name || 'User'}!
        </h1>
            {profileData.profile_image && (
          <div
            className={`w-40 h-40 mt-5 rounded-full overflow-hidden ${
              profileData.is_stakeholder ? 'border-4 border-green-500' : ''
            }`}
          >
            <img
              src={profileData.profile_image}
              alt="Profile"
              className='w-full h-full object-cover'
            />
          </div>
        )}

            <div className='grid grid-cols-1 lg:flex w-[100%] gap-10'>
              <div className='lg:w-[40%]'>
                <h2 className='text-start my-10 text-2xl font-bold text-black border-b-2 p-4 border-b-black'>Personal Info</h2>
                <div className='flex gap-5'>
                  <Form.Item name="first_name" label="First Name" rules={[{ required: true, message: ' Enter your first name!' }]}>
                    <Input className='h-12' />
                  </Form.Item>

                  <Form.Item name="last_name" label="Last Name" rules={[{ required: true, message: ' Enter your last name!' }]}>
                    <Input className='h-12' />
                  </Form.Item>
                </div>

                <div className='flex gap-5'>
                  <Form.Item name="email" label="Email" rules={[{ required: true, message: ' Enter your email!' }]}>
                    <Input className='h-12' disabled />
                  </Form.Item>

                  <Form.Item name="phone_number" label="Phone Number" rules={[{ required: true, message: ' Enter your valid phone number!' }]}>
                    <Input type='number' className='h-12' />
                  </Form.Item>
                </div>

                <div className='flex gap-5'>
                  <Form.Item name="residential_address" label="Residential Address">
                    <Input className='h-12'disabled />
                  </Form.Item>
                </div>

                <div className='flex gap-5'>
                  <Form.Item name="country_of_origin" label="Country Of Origin">
                    <Input className='h-12'disabled />
                  </Form.Item>

                  <Form.Item name="state_of_origin" label="State Of Origin">
                    <Input className='h-12' disabled/>
                  </Form.Item>

                  <Form.Item name="lga_of_origin" label="LGA/Province/County">
                    <Input className='h-12' disabled/>
                  </Form.Item>
                </div>
              </div>

              <div className='lg:w-[40%]'>
                <h2 className='text-start my-10 text-2xl font-bold text-black border-b-2 p-4 border-b-black'>Bethel Info</h2>

                <Form.Item name="bethel" label="Bethel">
                  <Input className='h-12' disabled/>
                </Form.Item>

                <div className='flex gap-10'>
                  <Form.Item name="country_of_residence" label="Country of Residence">
                    <Input className='h-12' disabled/>
                  </Form.Item>

                  <Form.Item name="residing_state" label="State">
                    <Input className='h-12' disabled/>
                  </Form.Item>

                  <Form.Item name="nation_of_worship" label="Nation of Worship">
                    <Input className='h-12' disabled/>
                  </Form.Item>
                </div>

            <div className='flex gap-10'>
                <Form.Item name="next_of_kin1" label="Next of Kin">
                  <Input className='h-12 w-44' disabled/>
                </Form.Item>

                
                <Form.Item name="pew" label="Pew">
                    <Input className='h-12' disabled />
                </Form.Item>
            </div>

                <div className='flex gap-5 items-center'>

                </div>
              </div>

              {/* <Form.Item label="Profile Image">
                <Upload {...uploadProps} listType="picture">
                  <Button className='h-16 w-28' icon={<UploadOutlined />}>Upload</Button>
                </Upload>
                {profileData.profile_image && (
                  <img src={profileData.profile_image} alt="Profile" className="w-20 h-20 mt-2 rounded-full" />
                )}
              </Form.Item> */}

<Form.Item label="Profile Image">
  <div className="relative">
    <img
      src={
        imagePreview ||
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5seV9y_a20glyuiIKDENNpXYgWDuNFnQ5fIopKOrpsQ_ms8Kt33kZJSiS7m6JwbVIqfM&usqp=CAU"
      }
      alt="Profile Preview"
      className="w-40 h-40 rounded-full object-cover border-2 border-blue-500"
    />
    <Button
      type="primary"
      shape="circle"
      icon={<CameraOutlined color="#000" />}
      className="absolute bottom-5 right-0 bg-blue-500"
      onClick={() => setCameraOpen(true)}
    />
  </div>
</Form.Item>

<Modal open={cameraOpen} footer={null} onCancel={() => setCameraOpen(false)}>
  <Webcam
    audio={false}
    ref={webcamRef}
    screenshotFormat="image/jpeg"
    className="w-full h-auto"
  />
  <Button type="primary" className="mt-2 w-full" onClick={capturePhoto}>
    Capture Photo
  </Button>
</Modal>

              
            </div>

            <div className='mt-10 flex justify-center gap-5'>
              <Button className='bg-red-400 w-28 h-10' type="primary" onClick={handleLogout}>
                Logout
              </Button>

              <Button className='bg-blue-500 w-28 h-10' type="primary" htmlType="submit" loading={loading}>
                <IoMdArrowForward /> Complete
              </Button>
            </div>
          </Form>

            <div>
                  <Drawer
                    title="Basic Drawer"
                    placement={placement}
                    closable={false}
                    onClose={onClose}
                    open={open}
                    key={placement}
                    extra={
                      <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button className='bg-blue-500' type="primary" onClick={onClose}>
                          OK
                        </Button>
                      </Space>
                    }
                  >
                   <ChildRegister />
                
                  </Drawer>

                  <Drawer
                    title="Basic Drawer"
                    width={600}
                    placement={placement2}
                    closable={false}
                    onClose={onClose2}
                    open={open2}
                    key={placement2}
                    extra={
                      <Space>
                        <Button onClick={onClose2}>Cancel</Button>
                        <Button className='bg-blue-500' type="primary" onClick={onClose2}>
                          OK
                        </Button>
                      </Space>
                    }
                  >
                   <ChildrenList />
                  </Drawer>
            </div>

        </div>
              </div>
    );
  };

  export default Profile;